import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import type { UnstyledListTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import type { BadgeSetConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import type { BadgeViewFragment } from '@aurora/shared-generated/types/graphql-types';
import { calculateBadgesToDisplay } from '@aurora/shared-client/helpers/badges/BadgesHelper';
import React from 'react';
import { BadgeViewVariant } from '../../../types/enums';
import type { EarnedBadgeFragment } from '../../../types/graphql-types';
import BadgeView from '../BadgeView/BadgeView';
import { useClassNameMapper } from 'react-bootstrap';
import type { BadgeViewIconProps, BadgeViewInlineProps } from '../BadgeView/types';

interface Props {
  /**
   * The earned badges list.
   */
  earnedBadges: EarnedBadgeFragment[];
  /**
   * The badge view variant to render inside the list.
   */
  badgeViewVariant: BadgeViewVariant;
  /**
   * The badge set list.
   */
  badgeSetList?: BadgeSetConnection;
  /**
   * The badge set list size.
   */
  badgeSetListSize?: number;
  /**
   * Class name(s) to apply to the List items
   */
  className?: string;
  /**
   * The size for each badge
   */
  size?: IconSize;
}

/**
 * The user badges list component that takes the earnedBadges list and the badge set list.
 * Depends on the badge view variant, the component will renders the latest earned badges of each set and if the user don't have earned badges as part of the set,
 * will render the first not hidden unearned badge.
 *
 * @constructor
 *
 * @author Gaston Manzano
 */
const UserBadgesList: React.FC<React.PropsWithChildren<Props>> = ({
  earnedBadges,
  badgeSetList,
  badgeViewVariant,
  badgeSetListSize = 5,
  className,
  size = IconSize.PX_60
}) => {
  const cx = useClassNameMapper();

  const listVariant: UnstyledListTypeAndProps<BadgeViewFragment> = {
    type: ListVariant.UNSTYLED,
    props: {
      useTransitions: false,
      listItemClassName() {
        return cx('lia-g-mb-0');
      }
    }
  };

  const itemsToDisplay =
    badgeViewVariant === BadgeViewVariant.ICON
      ? calculateBadgesToDisplay(earnedBadges, badgeSetList, badgeSetListSize)
      : earnedBadges;

  /**
   * Obtain the props for inline or icon variant.
   */
  function calculateProps(badge: EarnedBadgeFragment): BadgeViewInlineProps | BadgeViewIconProps {
    return badgeViewVariant === BadgeViewVariant.INLINE
      ? {
          revokedDate: badge.revokedDate
        }
      : {
          isEarned: badge.earnedDate !== null,
          size: size
        };
  }

  return (
    <List items={itemsToDisplay} variant={listVariant} className={className}>
      {item => (
        <BadgeView
          entity={item.badge}
          variant={{
            type: badgeViewVariant,
            props: calculateProps(item)
          }}
          key={item.badge.id}
        />
      )}
    </List>
  );
};
export default UserBadgesList;
