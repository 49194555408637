import type { IconColor } from '@aurora/shared-client/components/common/Icon/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { UserSolutionCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './UserSolutionCount.module.css';

interface Props {
  /**
   * The user for which solution count is displayed for.
   */
  user: UserSolutionCountFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the icon element.
   */
  iconClassName?: string;

  /** Color to apply to the icon element */
  iconColor?: IconColor;

  /** Size to apply to the icon element */
  iconSize?: IconSize;

  /**
   * Include an icon in the component element.
   */
  useIcon?: boolean;
  /**
   * Include text in the component element.
   */
  useText?: boolean;
  /**
   * Set a custom element for message count component.
   */
  countElement?: React.ElementType;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Displays the number of soultion provided by user.
 *
 * @constructor
 *
 * @author Shalin Amin
 */
const UserSolutionCount: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'strong',
  countElement: SolutionCountComponent = 'span',
  className,
  iconClassName,
  iconColor,
  iconSize = IconSize.PX_16,
  useIcon = false,
  useText = true,
  useTable = false,
  textClassName,
  countClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    FormattedMessage,
    formatMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.USER_SOLUTION_COUNT);

  if (textLoading) {
    return null;
  }

  const { solutionsCount } = user;

  return (
    <Component
      title={formatMessage('userSolution')}
      className={cx(className, 'lia-solution-count')}
    >
      {useIcon && (
        <Icon
          icon={Icons.CheckmarkSolidIcon}
          size={iconSize}
          color={iconColor}
          className={cx(iconClassName)}
        />
      )}
      {useText || useTable ? (
        <FormattedMessage
          id="title"
          values={{
            count: solutionsCount,
            span: function renderChunks(chunks): React.ReactNode {
              return <span className={cx(textClassName)}>&nbsp;{chunks}</span>;
            },
            solutionCountRender: function renderCount(): React.ReactNode {
              return (
                <SolutionCountComponent className={cx(countClassName)}>
                  {solutionsCount}
                </SolutionCountComponent>
              );
            }
          }}
        />
      ) : (
        <span>{solutionsCount}</span>
      )}
    </Component>
  );
};

export default UserSolutionCount;
