import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import type {
  BadgePropertiesQuery,
  BadgePropertiesQueryVariables
} from '../../types/graphql-types';
import badgePropertiesQuery from './BadgeProperties.query.graphql';

const log = getLog(module);

interface BadgePropertiesResult {
  /**
   * Is this loading?
   */
  badgePropertiesLoading: boolean;
  /**
   * Whether the badges feature is enabled or not.
   */
  badgesEnabled: boolean;
}

/**
 * Gets badge properties.
 *
 * @param module The module
 * @author Gaston Manzano
 */
export default function useBadgeProperties(module: NodeModule | string): BadgePropertiesResult {
  const result = useQueryWithTracing<BadgePropertiesQuery, BadgePropertiesQueryVariables>(
    module,
    badgePropertiesQuery
  );

  if (result?.error) {
    log.error(result.error, 'Error getting badge properties');
  }

  return {
    badgePropertiesLoading: result.loading,
    badgesEnabled: checkPolicy(result.data?.community.badgeProperties.enabled)
  };
}
