import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { UserMessagesCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localstyles from './UserMessagesCount.module.css';

interface Props {
  /**
   * The user to display the messages count for.
   */
  user: UserMessagesCountFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Set a custom element for message count component.
   */
  countElement?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the icon element.
   */
  iconClassName?: string;

  /** Color to apply to the icon element */
  iconColor?: IconColor;

  /** Size to apply to the icon element */
  iconSize?: IconSize;

  /**
   * Include an icon in the component element.
   */
  useIcon?: boolean;
  /**
   * Include text in the component element.
   */
  useText?: boolean;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Display a user's messages count.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const UserMessagesCount: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'small',
  countElement: MessageCountComponent = 'span',
  className,
  iconClassName,
  iconColor = IconColor.GRAY_600,
  iconSize = IconSize.PX_16,
  useIcon = false,
  useText = true,
  useTable = false,
  textClassName,
  countClassName
}) => {
  const cx = useClassNameMapper(localstyles);
  const {
    FormattedMessage,
    formatMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.USER_MESSAGES_COUNT);

  if (textLoading) {
    return null;
  }

  const { messagesCount } = user;

  return !textLoading && messagesCount ? (
    <Component
      title={formatMessage('postsAuthored')}
      className={cx(className, 'lia-messages-count')}
      data-testid="UserMessagesCount"
    >
      {useIcon && (
        <Icon
          icon={Icons.CommentIcon}
          color={iconColor}
          size={iconSize}
          className={cx(iconClassName, 'lia-g-mr-5')}
          testId="UserMessagesCount.CommentIcon"
        />
      )}
      {useText || useTable ? (
        <FormattedMessage
          id="title"
          values={{
            count: messagesCount,
            span: function renderChunks(chunks): React.ReactNode {
              return <span className={cx(textClassName)}>&nbsp;{chunks}</span>;
            },
            messageCountRender: function renderCount(): React.ReactNode {
              return (
                <MessageCountComponent
                  className={cx(countClassName)}
                  data-testid="UserMessagesCount.Count"
                >
                  {messagesCount}
                </MessageCountComponent>
              );
            }
          }}
        />
      ) : (
        <span>{messagesCount}</span>
      )}
    </Component>
  ) : null;
};

export default UserMessagesCount;
