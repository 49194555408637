import type { UserRankFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import UserRankLabel from '../../ranks/UserRankLabel/UserRankLabel';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The user to display the rank for.
   */
  user: UserRankFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the label element.
   */
  labelClassName?: string;
}

/**
 * Display a user's rank.
 * @author Arjun Krishna
 */
const UserRank: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'div',
  className,
  labelClassName
}) => {
  const cx = useClassNameMapper();
  const { loading: textLoading } = useTranslation(SharedComponent.USER_RANK);

  return !textLoading && user?.rank ? (
    <Component className={cx(className)} data-testid="userRank">
      <UserRankLabel rank={user.rank} className={labelClassName} />
    </Component>
  ) : null;
};

export default UserRank;
