import type { ApolloCache } from '@apollo/client';
import type {
  Badge,
  BadgeEdge,
  BadgeSetConnection,
  BadgeSet,
  BadgeSetEdge,
  BadgeConnection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { Tenant } from '@aurora/shared-types/tenant/index';
import badgeSetQuery from '../../components/badges/BadgeSets.query.graphql';
import { buildBasePath } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import type {
  BadgeSetsQuery,
  BadgeSetsQueryVariables,
  BadgeSetViewFragment,
  BadgeViewFragment,
  EarnedBadgeFragment
} from '@aurora/shared-generated/types/graphql-types';
import placeholderBadge from './placeholderBadge';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import type { PathFunction } from 'path-to-regexp';
import { compile } from 'path-to-regexp';
import { safeEncodeUriComponent } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import type { CommonAssetParams } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';

const badgeSetsLimit = 400;
const maxFeaturedBadgeSetsAllowed = 5;

function getBadgeSetsQueryVariables(): BadgeSetsQueryVariables {
  return {
    first: badgeSetsLimit
  };
}

const BADGES_URL = '/:contextName?/:servicePathChar?/:community?/badgesAwardedMembers/:assetName';

const badgesUrlPathCallback: PathFunction<CommonAssetParams> = compile<CommonAssetParams>(
  BADGES_URL,
  {
    encode: safeEncodeUriComponent
  }
);

/**
 * Adds a badge set to the cache
 * @param cache the apollo cache
 * @param badgeSet the badge set to cache
 */
function addBadgeSetToCache(cache: ApolloCache<{}>, badgeSet: BadgeSetViewFragment) {
  const variables: BadgeSetsQueryVariables = getBadgeSetsQueryVariables();

  const cachedBadgeSetQuery = cache.readQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    variables
  });

  const newEdge = {
    node: {
      ...badgeSet
    },
    cursor: null
  };

  const newEdges = [...cachedBadgeSetQuery.badgeSets.edges, newEdge];

  const updateBadgeSetsQuery = {
    badgeSets: {
      ...cachedBadgeSetQuery.badgeSets,
      edges: newEdges
    }
  };

  cache.writeQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    data: updateBadgeSetsQuery,
    variables
  });
}

/**
 * Updates an existing badge set in cache.
 * @param cache the apollo cache
 * @param badgeSet The badge to update.
 */
function updateBadgeSetInCache(cache: ApolloCache<{}>, badgeSet: BadgeSetViewFragment) {
  const id = cache.identify(badgeSet);
  cache.modify({
    id,
    fields: {
      name() {
        return badgeSet.name;
      },
      featured() {
        return badgeSet.featured;
      }
    }
  });
}

/**
 * Add a new badge to an existing badge set in cache.
 * @param tenant the tenant
 * @param cache the apollo cache
 * @param badgeSet The badge to update.
 * @param badge
 */
function addBadgeToBadgeSetInCache(
  tenant: Tenant,
  cache: ApolloCache<{}>,
  badgeSet: BadgeSetViewFragment,
  badge: BadgeViewFragment
) {
  const id = cache.identify(badgeSet);

  const newBadgeEdge = {
    node: placeholderBadge(
      tenant,
      {
        ...(badge as Badge)
      },
      true
    ),
    cursor: null
  };

  const updatedBadges = {
    badges: {
      edges: [...badgeSet.badges.edges, newBadgeEdge]
    }
  };

  cache.modify({
    id,
    fields: {
      badges() {
        return updatedBadges;
      }
    }
  });
}

/**
 * Deletes a badge set in the cache
 * @param cache the apollo cache
 * @param deletedBadgeSet the deleted badge set
 */
function deleteBadgeSetInCache(cache: ApolloCache<{}>, deletedBadgeSet: BadgeSetViewFragment) {
  const variables: BadgeSetsQueryVariables = getBadgeSetsQueryVariables();

  const cachedBadgeSetQuery = cache.readQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    variables
  });

  // eslint-disable-next-line unicorn/no-array-reduce
  const newEdges = cachedBadgeSetQuery.badgeSets.edges.reduce(
    (edges: BadgeSetEdge[], currentEdge: BadgeSetEdge) => {
      // exclude the deleted badge set from the list and update remaining badge sets position
      if (currentEdge.node.id != deletedBadgeSet.id) {
        if (currentEdge.node.position > deletedBadgeSet.position) {
          const updatedEdge: BadgeSetEdge = {
            ...currentEdge,
            node: {
              ...currentEdge.node,
              position: currentEdge.node.position - 1
            }
          };
          edges.push(updatedEdge);
        } else {
          edges.push(currentEdge);
        }
      }

      return edges;
    },
    []
  );

  const updateBadgeSetQuery = {
    badgeSets: {
      ...cachedBadgeSetQuery.badgeSets,
      edges: newEdges
    }
  };

  cache.writeQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    data: updateBadgeSetQuery,
    variables
  });
}

/**
 * Delete a badge from an existing badge set in cache.
 * @param cache the apollo cache
 * @param badgeToDelete
 */
function deleteBadgeFromBadgeSetInCache(cache: ApolloCache<{}>, badgeToDelete: BadgeViewFragment) {
  const variables: BadgeSetsQueryVariables = getBadgeSetsQueryVariables();
  const cachedBadgeSetQuery = cache.readQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    variables
  });

  const badgeSetEdgeToUpdate = cachedBadgeSetQuery.badgeSets.edges.find(edge =>
    edge.node.badges.edges.some(badge => badge.node.id === badgeToDelete.id)
  );

  const id = cache.identify(badgeSetEdgeToUpdate.node);

  // eslint-disable-next-line unicorn/no-array-reduce
  const newEdges: BadgeEdge[] = badgeSetEdgeToUpdate.node.badges.edges.reduce(
    (edges: BadgeEdge[], currentEdge: BadgeEdge) => {
      // exclude the deleted badge from the list and update remaining badges position
      if (badgeToDelete.id != currentEdge.node.id) {
        if (currentEdge.node.position > badgeToDelete.position) {
          const updatedEdge: BadgeEdge = {
            ...currentEdge,
            node: {
              ...currentEdge.node,
              position: currentEdge.node.position - 1
            }
          };
          edges.push(updatedEdge);
        } else {
          edges.push(currentEdge);
        }
      }
      return edges;
    },
    []
  );

  const updatedBadges = {
    badges: {
      edges: [...newEdges]
    }
  };

  cache.modify({
    id,
    fields: {
      badges() {
        return updatedBadges;
      }
    }
  });
}

/**
 * Updates sets orders in the cache
 * @param cache the apollo cache
 * @param sets the list of sets to reorder
 */
function updateBadgeSetsOrderInCache(cache: ApolloCache<{}>, sets: Array<BadgeSetViewFragment>) {
  const variables: BadgeSetsQueryVariables = getBadgeSetsQueryVariables();

  const setsSorted: BadgeSet[] = [];
  sets.forEach((set, idx) => setsSorted.push({ ...(set as BadgeSet), position: idx }));

  const setsEdges: BadgeSetEdge[] = setsSorted.map(set => {
    return {
      __typename: 'BadgeSetEdge',
      node: set
    };
  });

  const updateSetsQuery: BadgeSetsQuery = {
    badgeSets: {
      __typename: 'BadgeSetConnection',
      edges: setsEdges
    }
  };

  cache.writeQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    data: updateSetsQuery,
    variables
  });
}

function getBadgeAwardedMembersUrl(tenant: Tenant, badgeId: string) {
  const { id } = IdConverter.decodeIdAsParts(tenant, badgeId);
  const { community, liaContextPath } = tenant;
  const [, contextName, servicePathChar] = liaContextPath?.split('/') ?? [];
  const basePath = buildBasePath<CommonAssetParams>(badgesUrlPathCallback, {
    contextName,
    servicePathChar,
    community,
    assetName: id
  });

  const url = UrlBuilder.fromUrl(basePath);
  if (process.env.NEXT_PUBLIC_URL_USE_HOST_IN_SERVLET_URL) {
    url.uri.host(tenant.host);
  }

  return url.build();
}

/**
 * Function used to order the badge sets with badges.
 * Sorting by position and prioritizing the badge sets with featured = true.
 */
function getOrderBadgeSets(badgeSetList: BadgeSetConnection) {
  const badgeSets = [...badgeSetList.edges].filter(badgeSet => badgeSet.node.badges.totalCount > 0);

  badgeSets?.sort((a, b) => {
    if (a.node.featured === b.node.featured) {
      return a.node.position - b.node.position;
    }

    return a.node.featured ? -1 : 1;
  });
  return badgeSets;
}

/**
 * Function used to calculate the badges to display (max 5).
 * Getting the last earned badge of each badge set and if the user doesn't have earned badges
 * will take the first of the badge set.
 */
function calculateBadgesToDisplay(
  earnedBadges: EarnedBadgeFragment[],
  badgeSetList: BadgeSetConnection,
  badgeSetListSize: number
) {
  const badgesToDisplay = [];
  const orderBadgeSets = getOrderBadgeSets(badgeSetList);

  orderBadgeSets.forEach((badgeSet: BadgeSetEdge) => {
    const { edges: badgeEdges } = badgeSet.node.badges;
    let lastBadge = null;
    const earnedBadgesMatches = [];

    //Matching the badges from the set with the earned badges of the user.
    badgeEdges.forEach((edge: BadgeEdge) => {
      const badge = edge.node;
      const matchingEarnedBadge = earnedBadges.find(
        earnedBadge => badge.id === earnedBadge.badge.id
      );

      if (matchingEarnedBadge) {
        earnedBadgesMatches.push({
          id: badge.id,
          badge: badge,
          earnedDate: matchingEarnedBadge.earnedDate,
          revokedDate: matchingEarnedBadge.revokedDate
        });
      } else if (!badge.hidden) {
        earnedBadgesMatches.push({
          id: badge.id,
          badge: badge,
          earnedDate: null,
          revokedDate: null
        });
      }
    });

    const hasEarnedBadges = earnedBadgesMatches.some(badge => badge.revokedDate === null);

    //if the user has earned badges on the set, calculates the latest earned.
    if (hasEarnedBadges) {
      const unRevokedDateBadges = earnedBadgesMatches.filter(badge => badge.revokedDate === null);
      unRevokedDateBadges.sort(
        (a, b) => new Date(b.earnedDate).getTime() - new Date(a.earnedDate).getTime()
      );
      [lastBadge] = unRevokedDateBadges;
    }

    //If the user hasn't earned badges on the set, get the first not hidden badge.
    if (!lastBadge && earnedBadgesMatches.some(badge => badge.hidden === false)) {
      lastBadge = { badge: badgeEdges[0].node, earnedDate: null, revokedDate: null };
    }

    //Add the last badge to badgesToDisplay
    if (lastBadge) {
      badgesToDisplay.push(lastBadge);
    }
  });

  return badgesToDisplay.slice(0, badgeSetListSize);
}

/**
 * Updates badge orders in the cache
 * @param cache the apollo cache
 * @param sets the list of sets to reorder
 */
function updateBadgesOrderInCache(cache: ApolloCache<{}>, sets: Array<BadgeSetViewFragment>) {
  const variables: BadgeSetsQueryVariables = getBadgeSetsQueryVariables();

  const orderedSetsWithBadges: BadgeSet[] = sets.map(set => {
    const badges: Badge[] = [];
    set.badges.edges.forEach((badge, index) => {
      badges.push({ ...(badge.node as Badge), position: index });
    });

    const updatedBadgesEdges: BadgeEdge[] = badges.map(badge => {
      return {
        __typename: 'BadgeEdge',
        node: badge
      };
    });

    const updatedBadgesConnection = {
      __typename: 'BadgeConnection',
      edges: updatedBadgesEdges,
      totalCount: updatedBadgesEdges.length
    };

    return {
      ...(set as BadgeSet),
      badges: updatedBadgesConnection as BadgeConnection
    };
  });

  const setsEdges: BadgeSetEdge[] = orderedSetsWithBadges.map(set => {
    return {
      __typename: 'BadgeSetEdge',
      node: set
    };
  });

  const updateSetsQuery: BadgeSetsQuery = {
    badgeSets: {
      __typename: 'BadgeSetConnection',
      edges: setsEdges
    }
  };

  cache.writeQuery<BadgeSetsQuery, BadgeSetsQueryVariables>({
    query: badgeSetQuery,
    data: updateSetsQuery,
    variables
  });
}

export {
  addBadgeSetToCache,
  addBadgeToBadgeSetInCache,
  badgeSetsLimit,
  maxFeaturedBadgeSetsAllowed,
  updateBadgeSetInCache,
  deleteBadgeSetInCache,
  deleteBadgeFromBadgeSetInCache,
  updateBadgeSetsOrderInCache,
  getBadgeAwardedMembersUrl,
  calculateBadgesToDisplay,
  updateBadgesOrderInCache
};
