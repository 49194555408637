import React from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import type { ItemType } from '../../../types/enums';
import { BadgeViewVariant } from '../../../types/enums';
import type { BadgeViewFragment } from '../../../types/graphql-types';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';

const log = getLog(module);

const BadgeViewStandard = dynamic(() => import('./BadgeViewStandard/BadgeViewStandard'));
const BadgeViewMain = dynamic(() => import('./BadgeViewMain/BadgeViewMain'));
const BadgeViewIcon = dynamic(() => import('./BadgeViewIcon/BadgeViewIcon'));
const BadgeViewInline = dynamic(() => import('./BadgeViewInline/BadgeViewInline'));

const variantToComponentMap: Record<
  BadgeViewVariant,
  ItemViewVariantFC<BadgeViewFragment, ItemType.BADGE, BadgeViewVariant>
> = {
  standard: BadgeViewStandard,
  main: BadgeViewMain,
  icon: BadgeViewIcon,
  inline: BadgeViewInline
};

/**
 * The base badge view component.
 *
 * @constructor
 * @author Gaston Manzano
 */
const BadgeView: ItemViewFC<BadgeViewFragment, ItemType.BADGE, BadgeViewVariant> = ({
  entity: badge,
  variant = { type: BadgeViewVariant.STANDARD },
  className
}) => {
  const BadgeViewComponent = variantToComponentMap[variant?.type];
  if (BadgeViewComponent) {
    return (
      <BadgeViewComponent
        entity={badge}
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...variant.props}
      />
    );
  }
  log.warn('No registered badge view for variant type [%s]', variant.type);
  return null;
};

export default BadgeView;
