import { RankStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { RankBasicFieldsFragment } from '@aurora/shared-generated/types/graphql-types';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import useCrossOriginAttribute from '../../useCrossOriginAttribute';
import useTranslation from '../../useTranslation';
import localStyles from './UserRankLabel.module.pcss';

interface Props {
  /**
   * The rank to display the label for.
   */
  rank: RankBasicFieldsFragment;
  /**
   * Class name(s) to apply to the label element.
   */
  className?: string;
}

/**
 * Renders a styled label for a Rank based on its Rank Style (text, filled, outlined).
 *
 * @author Martin Sandoval
 */
const UserRankLabel: React.FC<React.PropsWithChildren<Props>> = ({ rank, className }) => {
  const cx = useClassNameMapper(localStyles);
  const crossOrigin = useCrossOriginAttribute();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.USER_RANK_LABEL);

  if (textLoading || !rank) {
    return null;
  }

  const { name, icon, color, rankStyle } = rank;

  const iconUrl = icon?.url
    ? UrlHelper.adjustImageServerPageUrl(icon.url, { width: 100, height: 16 }, true)
    : null;

  const isBoxed = rankStyle !== RankStyle.Text;

  return (
    <div className={cx('lia-rank')}>
      {iconUrl ? (
        <img
          className={cx('lia-rank-icon')}
          src={iconUrl}
          alt={formatMessage('altTitle', { rankName: rank.name })}
          crossOrigin={crossOrigin(iconUrl)}
        />
      ) : null}
      {name?.length > 0 && (
        <span
          style={
            color
              ? {
                  borderColor: `#${color}`,
                  color: `#${color}`,
                  background: RankStyle.Filled === rankStyle ? `#${color}` : undefined
                }
              : undefined
          }
          className={cx(
            { 'lia-is-boxed': isBoxed },
            `lia-rank-label lia-is-${rankStyle?.toLowerCase()}`,
            className
          )}
        >
          <span className={cx('text-break lia-g-clamp')}>{name}</span>
        </span>
      )}
    </div>
  );
};

export default UserRankLabel;
