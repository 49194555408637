import React from 'react';
import { ListVariant } from './enums';
import type { ListProps } from '.';
import dynamic from 'next/dynamic';

const ListGroup = dynamic<ListProps<unknown>>(() => import('./ListGroup/ListGroup'));
const UnstyledList = dynamic<ListProps<unknown>>(() => import('./UnstyledList/UnstyledList'));
const CardList = dynamic<ListProps<unknown>>(() => import('./CardList/CardList'));
const GridList = dynamic<ListProps<unknown>>(() => import('./GridList/GridList'));
const TableList = dynamic<ListProps<unknown>>(() => import('./TableList/TableList'));
const TableListDraggable = dynamic<ListProps<unknown>>(
  () => import('./TableListDraggable/TableListDraggable')
);
const TableListDraggableNested = dynamic<ListProps<unknown>>(
  () => import('./TableListDraggableNested/TableListDraggableNested')
);
const UnwrappedList = dynamic<ListProps<unknown>>(() => import('./UnwrappedList/UnwrappedList'));

const listVariantToComponentMap: Record<ListVariant, React.ComponentType<ListProps<unknown>>> = {
  [ListVariant.LIST_GROUP]: ListGroup,
  [ListVariant.UNSTYLED]: UnstyledList,
  [ListVariant.CARD]: CardList,
  [ListVariant.GRID]: GridList,
  [ListVariant.TABLE]: TableList,
  [ListVariant.TABLE_DRAGGABLE]: TableListDraggable,
  [ListVariant.TABLE_DRAGGABLE_NESTED]: TableListDraggableNested,
  [ListVariant.UNWRAPPED]: UnwrappedList
};

function getListToType<T>(listVariantType: ListVariant): React.FC<ListProps<T>> {
  return listVariantToComponentMap[listVariantType] as React.FC<
    React.PropsWithChildren<ListProps<T>>
  >;
}

/**
 * Displays a list of items. The type of list is determined
 * by the specified variant.
 *
 * @constructor
 */
function List<T>({
  items,
  variant = { type: ListVariant.UNSTYLED },
  className,
  children,
  empty
}: ListProps<T>): React.ReactElement {
  const ListVariantComponent = getListToType<T>(variant.type);
  const hasItems = items?.length > 0;

  if (ListVariantComponent) {
    return (
      (hasItems || empty) && ( // eslint-disable-next-line react/jsx-props-no-spreading
        <ListVariantComponent className={className} items={items} empty={empty} {...variant.props}>
          {children}
        </ListVariantComponent>
      )
    );
  }
  return null;
}

export default List;
